@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&display=swap');

body {
    margin: 0;
    padding: 0;
    overflow-x:hidden;
}

/* src/App.css */
.leaflet-tooltip a {
    text-decoration: none;
}

@media (max-width: 600px) {

    .leaflet-tooltip,
    .leaflet-tooltip a {
        font-size: 16px !important;
    }
}

.jodit-wysiwyg{
    min-height: 300px !important;
}

.typing-indicator {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.typing-indicator span {
    width: 10px;
    height: 10px;
    background-color: #555;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
    animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
    animation-delay: 0.3s;
}

.typing-indicator span:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}